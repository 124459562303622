import type { ComputedRef } from 'vue';
import type {
  UserLoginRequestType,
  UserMeType,
  UserRegistrationRequestType,
  UserResetPasswordReqType,
  UserChangePasswordReqType, UserForgotPasswordSendLinkRequestType, UserChangeApiKeyRequestType
} from '~/composables/user/types/UserTypes';
import type { ServerResponseCommonType } from '~/types/commons';
import { userStore as store } from '~/stores/user-store';
import { storeToRefs, computed } from '#imports';

type TransportUserType = {
  userRegistration: (options: UserRegistrationRequestType) => Promise<ServerResponseCommonType | null>;
  userVerificationEmail: (hash: string) => Promise<ServerResponseCommonType | null>;
  userLogin: (options: UserLoginRequestType) => Promise<UserMeType | null>;
  getUserMe: () => Promise<UserMeType | undefined | null>;
  updateUserMe: () => Promise<UserMeType | undefined | null>;
  isLoggedIn: ComputedRef<boolean>;
  logOut: () => Promise<ServerResponseCommonType | null>;
  currentUser: UserMeType | null;
  userResendVerificationLink: () => Promise<ServerResponseCommonType | null>;
  userForgotPassword: (email: UserForgotPasswordSendLinkRequestType) => Promise<ServerResponseCommonType | null>;
  userResetPassword: (options: UserResetPasswordReqType) => Promise<ServerResponseCommonType | null>;
  userChangeApiKey: (options: UserChangeApiKeyRequestType) => Promise<ServerResponseCommonType | null>;
  userChangePassword: (options: UserChangePasswordReqType) => Promise<ServerResponseCommonType | null>;
}

export function useUserAuth (): TransportUserType {
  const userStore = store();
  const { $api } = useNuxtApp();
  const {
    isUserLoggedIn,
    userMe
  } = storeToRefs(userStore);

  const isLoggedIn = computed(() => isUserLoggedIn.value);
  const currentUser = userMe.value;

  const getUserMe = async (): Promise<UserMeType | null> => {
    if (userMe.value) {
      return userMe.value;
    }

    const data = await $api<ServerResponseCommonType<UserMeType>>('user/getPersonalInfo')
      .catch((err) => { throw err; });

    if (data && data.status === 'ok') {
      userStore.setUser(data.data);
      return data.data;
    }

    throw data;
  };
  const updateUserMe = async (): Promise<UserMeType | null> => {
    const data = await $api<ServerResponseCommonType<UserMeType>>('user/getPersonalInfo')
      .catch((err) => { throw err; });

    if (data && data.status === 'ok') {
      userStore.setUser(data.data);
      return data.data;
    }

    throw data;
  };

  const userLogin = async (options: UserLoginRequestType): Promise<UserMeType | null> => {
    const data = await $api<ServerResponseCommonType<UserMeType>>('user/login', {
      method: 'POST',
      body: { ...options }
    }).catch((err) => { throw err; });

    if (data && data.status === 'ok') {
      userStore.setUser(data.data);

      return data.data;
    }

    throw data;
  };

  const userRegistration = async (options: UserRegistrationRequestType): Promise<ServerResponseCommonType | null> => {
    const data = await $api<ServerResponseCommonType>('user/register', {
      method: 'POST',
      body: { ...options }
    }).catch((err) => { throw err; });

    if (data.status === 'error') {
      throw data;
    }

    return data;
  };

  const userVerificationEmail = async (hash: string): Promise<ServerResponseCommonType | null> => {
    return await $api<ServerResponseCommonType>(`user/email/verify/${hash}`, {
      method: 'GET'
    }).catch((err) => { throw err; });
  };

  const logOut = async (): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/logout', {
      method: 'POST'
    }).catch((err) => { throw err; });
    if (res?.status === 'ok') {
      userStore.setUser(null);
    }
    return res;
  };

  const userResendVerificationLink = async (): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/email/resendVerificationLink')
      .catch((err) => { throw err; });

    if (res?.status === 'ok') {
      return res;
    }

    return null;
  };

  const userForgotPassword = async (email: UserForgotPasswordSendLinkRequestType): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/forgotPassword/sendEmail', {
      method: 'POST',
      body: {email: email}
    }).catch((err) => { throw err; });

    if (res?.status === 'ok') {
      return res;
    }

    return null;
  };

  const userResetPassword = async (options: UserResetPasswordReqType): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/resetPassword', {
      method: 'POST',
      body: { ...options }
    }).catch((err) => { throw err; });

    if (res?.status === 'ok') {
      return res;
    }

    return null;
  };
  const userChangeApiKey = async (options: UserChangeApiKeyRequestType): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/changeApiKey', {
      method: 'POST',
      body: {...options}
    }).catch((err) => { throw err; });

    if (res?.status === 'ok') {
      return res;
    }

    return null;
  };

  const userChangePassword = async (options: UserChangePasswordReqType ): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/changePassword', {
      method: 'POST',
      body: { ...options }
    }).catch((err: ServerResponseCommonType) => { throw err.data });

    if (res?.status === 'ok') {
      return res;
    }

    return null;
  };

  return {
    isLoggedIn,
    currentUser,

    userChangePassword,
    userChangeApiKey,
    userResetPassword,
    userForgotPassword,
    userResendVerificationLink,
    logOut,
    getUserMe,
    userLogin,
    updateUserMe,
    userVerificationEmail,
    userRegistration
  };
}
