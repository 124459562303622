<template>
  <div class="password__popup-root">
    <div class="password__popup-bg" @click="closePasswordPopup"/>
    <div class="password__popup">
      <div class="password__title"> {{ $t('passwordChangePopup.title') }} </div>
      <div class="password__input-block">
        <label class="password__input-label"> {{ $t('passwordChangePopup.newPasswordInput')}} </label>
        <div class="password__input">
          <input
            v-model="newPassword"
            :type="newPasswordType"
            :placeholder="$t('passwordChangePopup.newPasswordInput')"
            class="password__input"
          />
          <img
            v-if="newPasswordIcon"
            @click="toggleInputType('newPassword')"
            class="password__input-image"
            src="~/assets/img/inputs/passlock-icon.svg"
          />
          <img
            v-if="!newPasswordIcon"
            @click="toggleInputType('newPassword')"
            class="password__input-image"
            src="~/assets/img/inputs/passUnlock-icon.svg"
          />
        </div>
      </div>
      <div class="password__input-block">
        <label class="password__input-label"> {{ $t('passwordChangePopup.doubleNewPasswordInput')}} </label>
        <div class="password__input">
          <input
              v-model="doubleNewPassword"
              :type="doubleNewPasswordType"
              :placeholder="$t('passwordChangePopup.doubleNewPasswordInput')"
              class="password__input"
          />
          <img
              v-if="doubleNewPasswordIcon"
              @click="toggleInputType('doubleNewPassword')"
              class="password__input-image"
              src="~/assets/img/inputs/passlock-icon.svg"
          />
          <img
              v-if="!doubleNewPasswordIcon"
              @click="toggleInputType('doubleNewPassword')"
              class="password__input-image"
              src="~/assets/img/inputs/passUnlock-icon.svg"
          />
        </div>
      </div>
      <button class="password__submit-button btn primary" @click="changePassword"> {{ $t('button.change') }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {changePasswordPopupStore } from '~/stores/changePassword-popup-store';
import {successPopupStore} from "~/stores/success-popup-store";
import {useUserAuth} from "~/composables/user/useUserAuth";
const { $i18n, $toast } = useNuxtApp();
const { closePasswordPopup } = changePasswordPopupStore();
const { openSuccessPopup } = successPopupStore();

const {
  currentUser,
  userChangePassword
} = useUserAuth();

const newPassword = ref(null);
const doubleNewPassword = ref(null);
const newPasswordType = ref('password');
const doubleNewPasswordType = ref('password');

const toggleInputType = function(inputName: any) {
  if (inputName === 'newPassword' && newPasswordType.value === 'password') {
    return newPasswordType.value = 'text';
  }
  if (inputName === 'newPassword' && newPasswordType.value === 'text') {
    return newPasswordType.value = 'password';
  }
  if (inputName === 'doubleNewPassword' && doubleNewPasswordType.value === 'password') {
    return doubleNewPasswordType.value = 'text';
  }
  if (inputName === 'doubleNewPassword' && doubleNewPasswordType.value === 'text') {
    return doubleNewPasswordType.value = 'password';
  }
}
const newPasswordIcon = computed(() => {
  return newPasswordType.value === 'password';
})
const doubleNewPasswordIcon = computed(() => {
  return doubleNewPasswordType.value === 'password'
})

const validationPassword = function() {
  if (newPassword.value !== doubleNewPassword.value) {
    return false
  }
  if (newPassword.value === null) {
    return false
  }
  if (String(newPassword.value).length < 8 || String(doubleNewPassword.value).length < 8 ) {
    return false
  }
  return true
}

async function changePassword() {
  if (!validationPassword()) {
    $toast.error($i18n.t('toast.changePassword.error'))
    return false
  }
  let body: any = {
    password: newPassword.value
  }
  const res: any  = await userChangePassword(body)
    .then((res) => {
      return res
    })
    .catch((err) => {
      return {error: err}
    });
  if (res && res.status === 'ok') {
    newPassword.value = null;
    doubleNewPassword.value = null;
    // $toast.success($i18n.t('toast.changePassword.success'))
    closePasswordPopup();
    openSuccessPopup();
  }
  if (res && res.error?.status === 'error') {
    $toast.error(res.error.message)
  }
}
</script>

<style scoped>
.password__popup-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 997;
  align-items: center;
  padding: 15% 0;
}
.password__popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.75);
  transition: 0.5s all;
}
.password__popup {
  background: white;
  width: 30%;
  border-radius: 24px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  transition: 0.5s all;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px #00000040;
  gap: 30px;
}
.password__title {
  color: #333333;
  font-size: 40px;
  font-weight: 600;
  line-height: 46.88px;
  text-align: center;
  padding-bottom: 2px;
}
.password__input-block {
  display: flex;
  flex-direction: column;
  //align-items: center;
}
.password__input-label {
  color: #666666;
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 600;
  text-align: center;
}
.password__input {
  position: relative;
  border-radius: 12px;
}
.password__input-image {
  position: absolute;
  top: 30%;
  right: 2%;
  opacity: 30%;
  cursor: pointer;
}
.password__submit-button {
  display: flex;
  width: 100%;
}
@media screen and (max-width: 1600px) {
  .password__popup {
    width: 50%;
  }
}
@media screen and (max-width: 859px) {
  .password__popup {
    width: 70%;
  }
}
@media screen and (max-width: 640px) {
  .password__popup {
    width: 90%;
  }
  .password__title {
    font-size: 25px;
    line-height: 27.38px;
    padding: 0;
  }
  .password__input-block {
    align-items: flex-start;
  }
  .password__input-label {
    font-size: 16px;
    line-height: 18.75px;
  }
}
</style>
