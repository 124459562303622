import type { Ref } from 'vue';

type ApiKeyPopupType = {
  isOpen: Ref<boolean>
  openChangeApiKeyPopup: () => void;
  closeChangeApiKeyPopup: () => void;
}

export const changeApiKeyPopupStore = defineStore('apiKeyPopup', (): ApiKeyPopupType => {
  const isOpen = ref<boolean>(false);
  const openChangeApiKeyPopup = () => {
    isOpen.value = true;
  };
  const closeChangeApiKeyPopup = () => {
    isOpen.value = false;
  }

  return {
    isOpen,
    openChangeApiKeyPopup,
    closeChangeApiKeyPopup
  };
});
