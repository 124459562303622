import { default as indexWhu6zBwC0GMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/authorization/index.vue?macro=true";
import { default as indexzYFIOsmy1sMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/api/index.vue?macro=true";
import { default as indexKjoEY7WZK5Meta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/index.vue?macro=true";
import { default as indexshm60zxsUvMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/numbers-history/index.vue?macro=true";
import { default as index74i9yw6UVrMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/payments/index.vue?macro=true";
import { default as indexKVHUo2lA3hMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/profile/index.vue?macro=true";
import { default as indexW6wFFLSvHdMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/forgot-password/index.vue?macro=true";
import { default as indexBuNseDEyqLMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/index.vue?macro=true";
import { default as indexU00byzzaA0Meta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/payment/result/index.vue?macro=true";
import { default as indexLHWQW3ER56Meta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/registration/index.vue?macro=true";
export default [
  {
    name: indexWhu6zBwC0GMeta?.name ?? "authorization___en___default",
    path: indexWhu6zBwC0GMeta?.path ?? "/authorization",
    meta: indexWhu6zBwC0GMeta || {},
    alias: indexWhu6zBwC0GMeta?.alias || [],
    redirect: indexWhu6zBwC0GMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexWhu6zBwC0GMeta?.name ?? "authorization___en",
    path: indexWhu6zBwC0GMeta?.path ?? "/en/authorization",
    meta: indexWhu6zBwC0GMeta || {},
    alias: indexWhu6zBwC0GMeta?.alias || [],
    redirect: indexWhu6zBwC0GMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexWhu6zBwC0GMeta?.name ?? "authorization___ru",
    path: indexWhu6zBwC0GMeta?.path ?? "/ru/authorization",
    meta: indexWhu6zBwC0GMeta || {},
    alias: indexWhu6zBwC0GMeta?.alias || [],
    redirect: indexWhu6zBwC0GMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexzYFIOsmy1sMeta?.name ?? "cabinet-api___en___default",
    path: indexzYFIOsmy1sMeta?.path ?? "/cabinet/api",
    meta: indexzYFIOsmy1sMeta || {},
    alias: indexzYFIOsmy1sMeta?.alias || [],
    redirect: indexzYFIOsmy1sMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexzYFIOsmy1sMeta?.name ?? "cabinet-api___en",
    path: indexzYFIOsmy1sMeta?.path ?? "/en/cabinet/api",
    meta: indexzYFIOsmy1sMeta || {},
    alias: indexzYFIOsmy1sMeta?.alias || [],
    redirect: indexzYFIOsmy1sMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexzYFIOsmy1sMeta?.name ?? "cabinet-api___ru",
    path: indexzYFIOsmy1sMeta?.path ?? "/ru/cabinet/api",
    meta: indexzYFIOsmy1sMeta || {},
    alias: indexzYFIOsmy1sMeta?.alias || [],
    redirect: indexzYFIOsmy1sMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexKjoEY7WZK5Meta?.name ?? "cabinet___en___default",
    path: indexKjoEY7WZK5Meta?.path ?? "/cabinet",
    meta: indexKjoEY7WZK5Meta || {},
    alias: indexKjoEY7WZK5Meta?.alias || [],
    redirect: indexKjoEY7WZK5Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexKjoEY7WZK5Meta?.name ?? "cabinet___en",
    path: indexKjoEY7WZK5Meta?.path ?? "/en/cabinet",
    meta: indexKjoEY7WZK5Meta || {},
    alias: indexKjoEY7WZK5Meta?.alias || [],
    redirect: indexKjoEY7WZK5Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexKjoEY7WZK5Meta?.name ?? "cabinet___ru",
    path: indexKjoEY7WZK5Meta?.path ?? "/ru/cabinet",
    meta: indexKjoEY7WZK5Meta || {},
    alias: indexKjoEY7WZK5Meta?.alias || [],
    redirect: indexKjoEY7WZK5Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexshm60zxsUvMeta?.name ?? "cabinet-numbers-history___en___default",
    path: indexshm60zxsUvMeta?.path ?? "/cabinet/numbers-history",
    meta: indexshm60zxsUvMeta || {},
    alias: indexshm60zxsUvMeta?.alias || [],
    redirect: indexshm60zxsUvMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexshm60zxsUvMeta?.name ?? "cabinet-numbers-history___en",
    path: indexshm60zxsUvMeta?.path ?? "/en/cabinet/numbers-history",
    meta: indexshm60zxsUvMeta || {},
    alias: indexshm60zxsUvMeta?.alias || [],
    redirect: indexshm60zxsUvMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexshm60zxsUvMeta?.name ?? "cabinet-numbers-history___ru",
    path: indexshm60zxsUvMeta?.path ?? "/ru/cabinet/numbers-history",
    meta: indexshm60zxsUvMeta || {},
    alias: indexshm60zxsUvMeta?.alias || [],
    redirect: indexshm60zxsUvMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: index74i9yw6UVrMeta?.name ?? "cabinet-payments___en___default",
    path: index74i9yw6UVrMeta?.path ?? "/cabinet/payments",
    meta: index74i9yw6UVrMeta || {},
    alias: index74i9yw6UVrMeta?.alias || [],
    redirect: index74i9yw6UVrMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: index74i9yw6UVrMeta?.name ?? "cabinet-payments___en",
    path: index74i9yw6UVrMeta?.path ?? "/en/cabinet/payments",
    meta: index74i9yw6UVrMeta || {},
    alias: index74i9yw6UVrMeta?.alias || [],
    redirect: index74i9yw6UVrMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: index74i9yw6UVrMeta?.name ?? "cabinet-payments___ru",
    path: index74i9yw6UVrMeta?.path ?? "/ru/cabinet/payments",
    meta: index74i9yw6UVrMeta || {},
    alias: index74i9yw6UVrMeta?.alias || [],
    redirect: index74i9yw6UVrMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVHUo2lA3hMeta?.name ?? "cabinet-profile___en___default",
    path: indexKVHUo2lA3hMeta?.path ?? "/cabinet/profile",
    meta: indexKVHUo2lA3hMeta || {},
    alias: indexKVHUo2lA3hMeta?.alias || [],
    redirect: indexKVHUo2lA3hMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVHUo2lA3hMeta?.name ?? "cabinet-profile___en",
    path: indexKVHUo2lA3hMeta?.path ?? "/en/cabinet/profile",
    meta: indexKVHUo2lA3hMeta || {},
    alias: indexKVHUo2lA3hMeta?.alias || [],
    redirect: indexKVHUo2lA3hMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKVHUo2lA3hMeta?.name ?? "cabinet-profile___ru",
    path: indexKVHUo2lA3hMeta?.path ?? "/ru/cabinet/profile",
    meta: indexKVHUo2lA3hMeta || {},
    alias: indexKVHUo2lA3hMeta?.alias || [],
    redirect: indexKVHUo2lA3hMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexW6wFFLSvHdMeta?.name ?? "forgot-password___en___default",
    path: indexW6wFFLSvHdMeta?.path ?? "/forgot-password",
    meta: indexW6wFFLSvHdMeta || {},
    alias: indexW6wFFLSvHdMeta?.alias || [],
    redirect: indexW6wFFLSvHdMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexW6wFFLSvHdMeta?.name ?? "forgot-password___en",
    path: indexW6wFFLSvHdMeta?.path ?? "/en/forgot-password",
    meta: indexW6wFFLSvHdMeta || {},
    alias: indexW6wFFLSvHdMeta?.alias || [],
    redirect: indexW6wFFLSvHdMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexW6wFFLSvHdMeta?.name ?? "forgot-password___ru",
    path: indexW6wFFLSvHdMeta?.path ?? "/ru/forgot-password",
    meta: indexW6wFFLSvHdMeta || {},
    alias: indexW6wFFLSvHdMeta?.alias || [],
    redirect: indexW6wFFLSvHdMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexBuNseDEyqLMeta?.name ?? "index___en___default",
    path: indexBuNseDEyqLMeta?.path ?? "/",
    meta: indexBuNseDEyqLMeta || {},
    alias: indexBuNseDEyqLMeta?.alias || [],
    redirect: indexBuNseDEyqLMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexBuNseDEyqLMeta?.name ?? "index___en",
    path: indexBuNseDEyqLMeta?.path ?? "/en",
    meta: indexBuNseDEyqLMeta || {},
    alias: indexBuNseDEyqLMeta?.alias || [],
    redirect: indexBuNseDEyqLMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexBuNseDEyqLMeta?.name ?? "index___ru",
    path: indexBuNseDEyqLMeta?.path ?? "/ru",
    meta: indexBuNseDEyqLMeta || {},
    alias: indexBuNseDEyqLMeta?.alias || [],
    redirect: indexBuNseDEyqLMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexU00byzzaA0Meta?.name ?? "payment-result___en___default",
    path: indexU00byzzaA0Meta?.path ?? "/payment/result",
    meta: indexU00byzzaA0Meta || {},
    alias: indexU00byzzaA0Meta?.alias || [],
    redirect: indexU00byzzaA0Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexU00byzzaA0Meta?.name ?? "payment-result___en",
    path: indexU00byzzaA0Meta?.path ?? "/en/payment/result",
    meta: indexU00byzzaA0Meta || {},
    alias: indexU00byzzaA0Meta?.alias || [],
    redirect: indexU00byzzaA0Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexU00byzzaA0Meta?.name ?? "payment-result___ru",
    path: indexU00byzzaA0Meta?.path ?? "/ru/payment/result",
    meta: indexU00byzzaA0Meta || {},
    alias: indexU00byzzaA0Meta?.alias || [],
    redirect: indexU00byzzaA0Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexLHWQW3ER56Meta?.name ?? "registration___en___default",
    path: indexLHWQW3ER56Meta?.path ?? "/registration",
    meta: indexLHWQW3ER56Meta || {},
    alias: indexLHWQW3ER56Meta?.alias || [],
    redirect: indexLHWQW3ER56Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexLHWQW3ER56Meta?.name ?? "registration___en",
    path: indexLHWQW3ER56Meta?.path ?? "/en/registration",
    meta: indexLHWQW3ER56Meta || {},
    alias: indexLHWQW3ER56Meta?.alias || [],
    redirect: indexLHWQW3ER56Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexLHWQW3ER56Meta?.name ?? "registration___ru",
    path: indexLHWQW3ER56Meta?.path ?? "/ru/registration",
    meta: indexLHWQW3ER56Meta || {},
    alias: indexLHWQW3ER56Meta?.alias || [],
    redirect: indexLHWQW3ER56Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20240912085634/pages/registration/index.vue").then(m => m.default || m)
  }
]